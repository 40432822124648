
///<reference path="../experimental.d.ts" />

namespace a2hs {

    export var deferredPrompt: BeforeInstallPromptEvent = null;

    export var button: HTMLButtonElement = null;

    export function render(button: HTMLButtonElement) {
        if (button === null) throw Error('button cannot be null');
        a2hs.button = button;
        if (a2hs.deferredPrompt === null) {
            button.disabled = true;
        } else {
            button.disabled = false;
            button.addEventListener('click', a2hs.click);
        }
    }

    export function click() {
        a2hs.button.removeEventListener('click', a2hs.click);
        a2hs.button.disabled = true;
        a2hs.deferredPrompt.prompt();
        a2hs.deferredPrompt.userChoice.then((result) => {
            if (result.outcome === 'accepted') {
                console.info('User accepted the A2HS prompt');
            } else {
                console.info('User dismissed the A2HS prompt');
            }
        });
        a2hs.deferredPrompt = null;
    }

}

window.addEventListener('beforeinstallprompt', (e: BeforeInstallPromptEvent) => {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    a2hs.deferredPrompt = e;
    // Show the button
    const button = a2hs.button;
    if (button !== null) {
        button.disabled = false;
    }
});
